import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'
const wemov = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="wemov">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>We Make Online Videos</h1>
          <span className="highlight"></span>
        </span>
        <span>Video and Animation Production</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture">
		  	<img src={'/img/works/wemov-feature.jpg'} alt="WeMov" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
              <img className="picture" src={'/img/single/wordpress-icon.png'} width="139" height="87" alt="Wordpress" />

            </div>
            <a className="button visitwebsite" href="https://wemakeonlinevideos.com.au/" target="_blank" rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Visit Website</a>
          </div>
        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>Video Production Melbourne, Sydney and Brisbane and that’s exactly what we do everyday for our clients Australia-wide. We’re an award-winning video production company that uses the power of video to help organisations tell their unique story and build future-proof relationships with their customers.</p>
        <p>Collaborating with WeMov team and designer to develop high quality customised WordPress responsive website for desktop, tablet and smartphone. Powered by WordPress with Advanced Custom Fields plugin.</p>
      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/wemakeonlinevideoscomau.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col">
          <img className="picture" src={'/img/single/wemakeonlinevideoscomau_ mobile.jpg'} alt="Mobile View" />
      </div>
      </div>
    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/south-melbourne-market" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">South Melbourne Market</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/bulk-buys" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">Bulk Buys</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default wemov


export const Head = () => (
  <>
    <title>We Make Online Videos - Tobstar</title>
  </>
)